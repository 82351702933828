import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import foregroundEventEmitter from 'shared/foreground/eventEmitter';
import { globalState } from 'shared/foreground/models';
import { useIsPDFViewAsHTML, usePersistentPdfSettings } from 'shared/foreground/stateHooks';
import { toggleEmailOriginalStyles } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/emails';
import {
  setPDFRotation,
  togglePDFHtmlViewForDocument,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/pdf';
import { toggleVideoOriginalTranscript } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/video';
import {
  setFocusedDocumentId,
  setVideoHeaderOpen,
  toggleAppearancePanelOpen,
  toggleVideoHeaderOpen,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { getNewPdfRotation } from 'shared/foreground/utils/getNewPdfRotation';
import type { AnyDocument, FirstClassDocument, UserEventWithDataUpdate } from 'shared/types';
import { Category } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { isDocumentThatCanBeShared, isDocumentWithUrl, isYouTubeUrl } from 'shared/typeValidators';
import nowTimestamp from 'shared/utils/dates/nowTimestamp';
import { BrowserType, getCurrentBrowser } from 'shared/utils/environment';
import { getAdjacentDocumentIdUsingIds } from 'shared/utils/getAdjacentDocumentId';
import urlJoin from 'shared/utils/urlJoin';

import { AdaptiveHeaderContext } from '../contexts';
import { useIsLeftSidebarHidden, useIsRightSidebarHidden } from '../hooks/hooks';
import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import { toggleHideRightSidebar } from '../stateUpdaters/sidebars';
import { useShortcutsMap } from '../utils/shortcuts';
import styles from './AdaptiveHeader.module.css';
import Button from './Button';
import DocumentActionButtons from './DocumentActionButtons';
import AppearancePanel from './DocumentReader/AppearancePanel';
import DocZoomDropdown from './Dropdown/DocZoomDropdown';
import DropdownDocHeader from './Dropdown/DropdownDocHeader';
import DropdownNotebookHeader from './Dropdown/DropdownNotebookHeader';
import DropdownVideoSettings from './Dropdown/DropdownVideoSettings';
import RotateLeftIcon from './icons/20StrokeRotateLeft';
import SnapshotIcon from './icons/20StrokeSnapshot';
import ViewAsTextIcon from './icons/20StrokeViewAsText';
import AppearanceIcon from './icons/AppearanceIcon';
import ChevronUpIcon from './icons/ChevronUpIcon';
import ToggleRightPanelIcon from './icons/ToggleRightPanelIcon';
import NumberInput from './NumberInput';
import { PdfTronContext } from './PDFViewer';
import PublicLinkButton from './PublicLinkButton';
import Spinner from './Spinner';
import Tooltip from './Tooltip';

// On a YouTube video there is a button that allows the user to toggle the header visibility.
export const useVideoHeaderIsHidden = () => {
  const isVideoSettingsPanelShown = globalState(
    useCallback((state) => state.isVideoSettingsPanelShown, []),
  );
  const isAppearancePanelShown = globalState(useCallback((state) => state.isAppearancePanelShown, []));
  const isDocMoreActionsDropdownOpen = globalState(
    useCallback((state) => state.isDocMoreActionsDropdownOpen, []),
  );
  const isVideoHeaderShown = globalState(useCallback((state) => state.isVideoHeaderShown, []));

  const setVideoHeaderHidden = useCallback((isHidden: boolean) => {
    setVideoHeaderOpen(!isHidden);
  }, []);

  return [
    !isVideoHeaderShown &&
      !isVideoSettingsPanelShown &&
      !isAppearancePanelShown &&
      !isDocMoreActionsDropdownOpen,
    setVideoHeaderHidden as (value: React.SetStateAction<boolean>) => void,
  ] as const;
};

// The header should hide when the user is scrolling down and show when the user is scrolling up.
// We also don't want to show/hide the header if the user is interacting with the document.
// E.g. When zooming in, the scroll position changes, but we don't want to change the header visibility.
const timeToWaitBeforeHidingHeader = 500;
const whiteListKeys = ['ArrowUp', 'ArrowDown', 'PageUp', 'PageDown', 'Home', 'End', ' '];

interface UseHeaderIsHiddenReturn {
  headerIsHidden: boolean;
  setHeaderIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
  isBorderVisible: boolean;
}
export const useHeaderIsHidden = ({
  scrollableAncestorRef,
}: { scrollableAncestorRef: React.MutableRefObject<HTMLElement | null> }): UseHeaderIsHiddenReturn => {
  const lastUserInteractionTimeRef = useRef<number>(0);
  const lastScrollTopRef = useRef<number>(0);
  const [headerIsHidden, setHeaderIsHidden] = useState(false);
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const scrollableElement = scrollableAncestorRef.current;

  useEffect(() => {
    let elementsToWatch: HTMLElement[] = [];

    const saveLastUserInteractionTime = () => {
      lastUserInteractionTimeRef.current = nowTimestamp();
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (whiteListKeys.includes(e.key)) {
        return;
      }

      saveLastUserInteractionTime();
    };

    document.addEventListener('click', saveLastUserInteractionTime);
    document.addEventListener('keydown', onKeyDown);

    setTimeout(() => {
      elementsToWatch = Array.from(document.querySelectorAll('input, textarea'));
      elementsToWatch.forEach((el) => {
        el.addEventListener('blur', saveLastUserInteractionTime);
      });
    }, 2000);

    return () => {
      document.removeEventListener('click', saveLastUserInteractionTime);
      document.removeEventListener('keydown', onKeyDown);

      elementsToWatch.forEach((el) => {
        el.removeEventListener('blur', saveLastUserInteractionTime);
      });
      elementsToWatch = [];
    };
  }, []);

  useEffect(() => {
    if (!scrollableElement) {
      return;
    }

    const onScroll = () => {
      // Adding this < 0 check because in Safari you can do like a pull to refresh
      // effect and that will cause the scrollTop to be negative.
      const newScrollTop = scrollableElement.scrollTop < 0 ? 0 : scrollableElement.scrollTop;
      const newShouldHide = newScrollTop > lastScrollTopRef.current;

      lastScrollTopRef.current = newScrollTop;

      setIsBorderVisible(newScrollTop < 25);

      const activeEl = document.activeElement;
      const isInputFocused =
        activeEl instanceof HTMLInputElement || activeEl instanceof HTMLTextAreaElement;

      if (isInputFocused) {
        return;
      }

      if (newShouldHide && window.isRadixDropdownOpen) {
        return;
      }

      const userJustInteracted =
        nowTimestamp() - lastUserInteractionTimeRef.current < timeToWaitBeforeHidingHeader;

      if (userJustInteracted) {
        return;
      }

      setHeaderIsHidden(newShouldHide);
    };

    scrollableElement.addEventListener('scroll', onScroll);

    return () => {
      scrollableElement.removeEventListener('scroll', onScroll);
    };
  }, [scrollableElement]);

  return { headerIsHidden, setHeaderIsHidden, isBorderVisible };
};

export const HeaderSeparator = ({ className = '' }: { className?: string }) => (
  <div className={`${className} ${styles.headerSeparator}`} />
);

const AppearanceButton = () => {
  return (
    <Tooltip content="Customize styles">
      <Button
        className={styles.actionButton}
        onClick={() => toggleAppearancePanelOpen({ userInteraction: 'click' })}
      >
        <AppearanceIcon />
      </Button>
    </Tooltip>
  );
};

const PDFOriginalOrTextViewButton = ({ docId, isPDFView }: { docId: string; isPDFView: boolean }) => {
  const tooltip = isPDFView ? 'Text view' : 'Original view';

  return (
    <>
      <Tooltip content={tooltip}>
        <Button
          className={`${styles.actionButton} ${isPDFView ? '' : styles.isActive}`}
          onClick={() => togglePDFHtmlViewForDocument(docId, isPDFView, 'click')}
        >
          <ViewAsTextIcon />
        </Button>
      </Tooltip>
    </>
  );
};
const EmailOriginalOrTextViewButton = ({
  docId,
  isOriginalView,
}: { docId: string; isOriginalView: boolean }) => {
  const tooltip = isOriginalView ? 'Clean view' : 'Original view';

  // By default, original view is not toggled (unlike pdfs), so we show original as "active" state
  // We may want to fix this when we fully ship original view and unify across pdfs, epubs, emails.
  return (
    <>
      <Tooltip content={tooltip}>
        <Button
          className={`${styles.actionButton} ${isOriginalView ? styles.isActive : ''}`}
          onClick={() => toggleEmailOriginalStyles(docId, { userInteraction: 'click' })}
        >
          <ViewAsTextIcon />
        </Button>
      </Tooltip>
    </>
  );
};

const RotatePDFLeftButton = ({ docId, shortcut }: { docId: string; shortcut: string[] }) => {
  const pdfSettings = usePersistentPdfSettings(docId);
  const currentRotation = pdfSettings?.rotation;

  const rotateToTheLeft = useCallback(() => {
    setPDFRotation(docId, getNewPdfRotation(currentRotation, true), { userInteraction: 'click' });
  }, [currentRotation, docId]);

  useKeyboardShortcutPreventDefault(shortcut, rotateToTheLeft, {
    description: 'Rotate PDF left',
  });

  return (
    <>
      <Tooltip content="Rotate left" shortcut={shortcut}>
        <Button className={styles.actionButton} onClick={rotateToTheLeft}>
          <RotateLeftIcon />
        </Button>
      </Tooltip>
    </>
  );
};

export const ToggleShowPannelsButton = (): JSX.Element => {
  const shortcutsMap = useShortcutsMap();
  const shortcut = shortcutsMap[ShortcutId.HideRightPanel];

  return (
    <Tooltip content="Show right panel" shortcut={shortcut}>
      <button
        className={[styles.actionButton, styles.hidePanelsButton].join(' ')}
        tabIndex={-1}
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          toggleHideRightSidebar({ userInteraction: 'click' });
        }}
        type="button"
      >
        <ToggleRightPanelIcon />
      </button>
    </Tooltip>
  );
};

const PDFPagination = memo(function PDFPagination() {
  const shortcutsMap = useShortcutsMap();
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>();

  const { headerIsHidden, setHeaderIsHidden } = useContext(AdaptiveHeaderContext);

  const { documentViewer, documentLoaded } = useContext(PdfTronContext);

  useEffect(() => {
    if (!documentViewer || !documentLoaded) {
      return;
    }

    setTotalPages(documentViewer.getPageCount());
    setCurrentPage(documentViewer.getCurrentPage());
  }, [documentViewer, documentLoaded]);

  const onPageChange = useCallback(
    (newPage: number) => {
      if (!documentViewer || !documentLoaded) {
        return;
      }

      documentViewer.setCurrentPage(newPage, false);
    },
    [documentViewer, documentLoaded],
  );

  const onPageNumberChanged = useCallback(
    (currentPageNum: number) => {
      if (!documentLoaded) {
        return;
      }

      setCurrentPage(currentPageNum);
    },
    [documentLoaded],
  );

  useEffect(() => {
    if (!documentViewer || !documentLoaded) {
      return;
    }

    documentViewer.addEventListener('pageNumberUpdated', onPageNumberChanged);

    return () => {
      documentViewer.removeEventListener('pageNumberUpdated', onPageNumberChanged);
    };
  }, [onPageNumberChanged, documentViewer, documentLoaded]);

  const onFocus = useCallback(() => {
    if (headerIsHidden && setHeaderIsHidden) {
      setHeaderIsHidden(false);
    }
  }, [headerIsHidden, setHeaderIsHidden]);

  const shortcut = useMemo(() => shortcutsMap[ShortcutId.PdfJumpToPage], [shortcutsMap]);

  if (!currentPage || !totalPages) {
    return null;
  }

  return (
    <NumberInput
      className={styles.jumpToPageInput}
      current={currentPage}
      total={totalPages}
      onChange={onPageChange}
      onFocus={onFocus}
      tooltipText="Jump to page..."
      shortcut={shortcut}
      instantUpdate={false}
    />
  );
});

export default function AdaptiveHeader({
  doc,
  parentPath,
  documentPathPrefix,
  listedDocumentIds,
  isNotebookView,
}: {
  doc: FirstClassDocument | null;
  parentPath: string;
  listedDocumentIds?: AnyDocument['id'][];
  documentPathPrefix?: string;
  isNotebookView?: boolean;
}): JSX.Element | null {
  const { headerIsHidden, setHeaderIsHidden, isBorderVisible } = useContext(AdaptiveHeaderContext);
  const autoAdvance = globalState(useCallback((state) => state.client.autoAdvance, []));
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const rightSidebarHidden = useIsRightSidebarHidden();
  const history = useHistory();
  const isAppearancePanelShown = globalState(useCallback((state) => state.isAppearancePanelShown, []));
  const isPDF = doc?.category === Category.PDF;
  const showHTMLContentForPDF = useIsPDFViewAsHTML(doc?.id);
  const isPDFView = useMemo(() => isPDF && !showHTMLContentForPDF, [isPDF, showHTMLContentForPDF]);
  const isSafari = useMemo(() => getCurrentBrowser() === BrowserType.Safari, []);

  const sourceUrl = useMemo(() => (doc && isDocumentWithUrl(doc) ? doc.url : undefined), [doc]);
  const isYoutube = useMemo(() => isYouTubeUrl(sourceUrl || ''), [sourceUrl]);
  const isVideo = doc?.category === Category.Video || isYoutube;
  const isEmail = doc?.category === Category.Email;

  const shortcutsMap = useShortcutsMap();

  const shouldDisplay = Boolean(doc) || isNotebookView;

  const navDocuments = useCallback(
    ({ offset }: { offset: number }) => {
      if (!doc || !listedDocumentIds) {
        return;
      }

      const newOpenDocumentId = getAdjacentDocumentIdUsingIds({
        currentDocumentId: doc.id,
        documentIds: listedDocumentIds,
        offset,
      });

      if (newOpenDocumentId) {
        history.push(urlJoin(['/', documentPathPrefix, 'read', newOpenDocumentId]));
      }
    },
    [doc, history, documentPathPrefix, listedDocumentIds],
  );

  const goToParentPath = useCallback((): void => {
    if (!doc || !parentPath) {
      return;
    }

    history.push(parentPath);

    if (!listedDocumentIds) {
      return;
    }

    const newFocusedDocumentId = getAdjacentDocumentIdUsingIds({
      currentDocumentId: doc.id,
      documentIds: listedDocumentIds,
      offset: +1,
      fallbackToNearbyIfNoNextDoc: true,
    });

    setFocusedDocumentId(newFocusedDocumentId, { userInteraction: 'unknown' });
  }, [doc, history, parentPath, listedDocumentIds]);

  const goToNextDocument = useCallback(() => navDocuments({ offset: +1 }), [navDocuments]);

  const afterAction = useCallback(
    () => (autoAdvance ? goToNextDocument() : goToParentPath()),
    [autoAdvance, goToNextDocument, goToParentPath],
  );

  // With auto-advance enabled, when you move a doc and then hit undo it should
  // bring you backwards into the previously moved document.
  useEffect(() => {
    const onUndone = ({ events: undoneEvents }: { events: UserEventWithDataUpdate[] }) => {
      if (!autoAdvance) {
        return;
      }

      const undoneTriageStatusEvent = undoneEvents.find(
        (e) => e.name === 'document-triage-status-updated',
      );

      if (undoneTriageStatusEvent) {
        const updatedItem = undoneTriageStatusEvent.dataUpdates.itemsUpdated[0];

        if (updatedItem.type === 'documents') {
          const docId = updatedItem.id;
          history.push(urlJoin([parentPath, 'read', docId]));
        }
      }
    };

    foregroundEventEmitter.on('undid-action', onUndone);

    return () => {
      foregroundEventEmitter.off('undid-action', onUndone);
    };
  }, [autoAdvance, history, parentPath]);

  const className = useMemo(() => {
    const classes = [styles.adaptiveHeader];

    if (leftSidebarHidden) {
      classes.push(styles.leftSidebarHidden);
    }

    if (rightSidebarHidden) {
      classes.push(styles.rightSidebarHidden);
    }

    if (isSafari) {
      classes.push(styles.isSafari);
    }

    if (isVideo) {
      classes.push(styles.isVideo);
    }

    return classes.join(' ');
  }, [leftSidebarHidden, rightSidebarHidden, isSafari, isVideo]);

  const left = useMemo(() => {
    if (!shouldDisplay) {
      return null;
    }

    if (isNotebookView) {
      return <DefaultLeftHeader isAppearancePanelShown={isAppearancePanelShown} />;
    }

    if (isPDF) {
      return (
        <PDFLeftHeader
          doc={doc}
          isAppearancePanelShown={isAppearancePanelShown}
          isPDFView={isPDFView}
          shortcutsMap={shortcutsMap}
        />
      );
    }

    if (doc && isVideo) {
      return <VideoLeftHeader isAppearancePanelShown={isAppearancePanelShown} doc={doc} />;
    }

    if (isEmail) {
      return <EmailLeftHeader isAppearancePanelShown={isAppearancePanelShown} doc={doc} />;
    }

    return <DefaultLeftHeader isAppearancePanelShown={isAppearancePanelShown} />;
  }, [
    shouldDisplay,
    isNotebookView,
    isPDF,
    isVideo,
    isAppearancePanelShown,
    doc,
    isPDFView,
    shortcutsMap,
    isEmail,
  ]);

  const center = useMemo(() => {
    if (!shouldDisplay) {
      return null;
    }

    if (isPDFView) {
      return <PDFPagination />;
    }

    return null;
  }, [shouldDisplay, isPDFView]);

  const right = useMemo(() => {
    if (!doc) {
      return null;
    }

    if (isNotebookView) {
      return <NotebookRightHeader parentDocument={doc} />;
    }

    let publicLinkButton: JSX.Element | null = null;

    if (isDocumentThatCanBeShared(doc)) {
      publicLinkButton = <PublicLinkButton docId={doc.id} />;
    }

    return (
      <DefaultRightHeader
        doc={doc}
        parentPath={parentPath}
        afterAction={afterAction}
        publicLinkButton={publicLinkButton}
        isVideo={isVideo}
      />
    );
  }, [afterAction, doc, parentPath, isNotebookView, isVideo]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <>
      {headerIsHidden && (
        <div
          className={`${styles.hiddenHeader} ${isYoutube ? styles.hiddenYouTubeHeader : ''}`}
          onMouseEnter={() => setHeaderIsHidden?.(false)}
        />
      )}
      <div
        className={`${className} ${headerIsHidden ? styles.isHidden : ''} ${
          isBorderVisible ? styles.isTop : ''
        }`}
      >
        <div className={styles.adaptiveHeaderContent}>
          <div className={isNotebookView ? styles.notebookHeaderContent : styles.docHeaderContent}>
            <div className={styles.left}>{left}</div>
          </div>

          <div className={styles.center}>{center}</div>

          <div className={styles.right}>
            {right}
            {rightSidebarHidden && (
              <>
                <HeaderSeparator />
                <ToggleShowPannelsButton />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

interface PDFHeaderProps {
  doc: FirstClassDocument;
  isAppearancePanelShown: boolean;
  isPDFView: boolean;
  shortcutsMap: {
    [x: string]: string[];
  };
}

const PDFSnapshotToolButton = ({ shortcut }: { shortcut: string[] }) => {
  const [isActive, setIsActive] = useState(false);
  const { documentViewer } = useContext(PdfTronContext);

  const onToolUpdated = useCallback((newToolObject) => {
    setIsActive(newToolObject.name === 'AnnotationCreateRectangle');
  }, []);

  useEffect(() => {
    if (!documentViewer) {
      return;
    }

    documentViewer.addEventListener('toolModeUpdated', onToolUpdated);

    return () => {
      documentViewer.removeEventListener('toolModeUpdated', onToolUpdated);
    };
  }, [onToolUpdated, documentViewer]);

  const setToolRectangleArea = useCallback(() => {
    if (!documentViewer) {
      return;
    }

    if (isActive) {
      documentViewer.setToolMode(documentViewer.getTool(window.Core.Tools.ToolNames.HIGHLIGHT));
    } else {
      documentViewer.setToolMode(documentViewer.getTool(window.Core.Tools.ToolNames.RECTANGLE));
    }
  }, [documentViewer, isActive]);

  return (
    <Tooltip content="Select area" shortcut={shortcut}>
      <Button
        className={`${styles.pdfSnapshotButton} ${isActive ? styles.isActive : ''}`}
        variant="primary"
        onClick={setToolRectangleArea}
      >
        <SnapshotIcon />
      </Button>
    </Tooltip>
  );
};

const PDFLeftHeader = ({ doc, isAppearancePanelShown, shortcutsMap, isPDFView }: PDFHeaderProps) => {
  return (
    <>
      <AppearanceButton />
      <div className={styles.divider} />
      {isAppearancePanelShown && (
        <AppearancePanel showTitle={false} className={styles.appearancePanel} isPDFView={isPDFView} />
      )}
      <PDFOriginalOrTextViewButton docId={doc.id} isPDFView={isPDFView} />
      {isPDFView && (
        <RotatePDFLeftButton docId={doc.id} shortcut={shortcutsMap[ShortcutId.PdfRotateLeft]} />
      )}
      {isPDFView && <PDFSnapshotToolButton shortcut={shortcutsMap[ShortcutId.PdfSnapshot]} />}
      {isPDFView && <DocZoomDropdown docId={doc.id} />}
    </>
  );
};

interface VideoLeftHeaderProps {
  doc: FirstClassDocument;
  isAppearancePanelShown: boolean;
}

const YouTubeOriginalOrEnhancedTranscriptButton = ({
  docId,
  isEnhancedTranscript,
  enhancedTranscriptStatus,
}: {
  docId: string;
  isEnhancedTranscript: boolean;
  enhancedTranscriptStatus: 'pending' | 'done' | undefined;
}) => {
  if (enhancedTranscriptStatus === 'pending') {
    return (
      <Tooltip content="Enhacing transcript...">
        <Spinner className={styles.enhacingTranscriptSpinner} />
      </Tooltip>
    );
  }

  const tooltip = isEnhancedTranscript ? 'Original transcript' : 'Enhanced transcript';

  return (
    <>
      <Tooltip content={tooltip}>
        <Button
          className={`${styles.actionButton} ${isEnhancedTranscript ? styles.isActive : ''}`}
          onClick={() => toggleVideoOriginalTranscript(docId, { userInteraction: 'click' })}
        >
          <ViewAsTextIcon />
        </Button>
      </Tooltip>
    </>
  );
};

const VideoLeftHeader = ({ doc, isAppearancePanelShown }: VideoLeftHeaderProps) => {
  const isEnhancedTranscript = Boolean(
    doc?.source_specific_data?.video?.show_enhanced_youtube_transcript,
  );
  return (
    <>
      <AppearanceButton />
      {isAppearancePanelShown && (
        <AppearancePanel showTitle={false} className={styles.appearancePanel} />
      )}
      <YouTubeOriginalOrEnhancedTranscriptButton
        docId={doc.id}
        isEnhancedTranscript={isEnhancedTranscript}
        enhancedTranscriptStatus={doc?.source_specific_data?.video?.enhanced_youtube_transcript_status}
      />
      <DropdownVideoSettings />
    </>
  );
};

interface EmailLeftHeaderProps {
  doc: FirstClassDocument;
  isAppearancePanelShown: boolean;
}

const EmailLeftHeader = ({ isAppearancePanelShown, doc }: EmailLeftHeaderProps) => {
  const isOriginalView = Boolean(doc?.source_specific_data?.email?.originalEmailView);
  return (
    <>
      <AppearanceButton />
      {isAppearancePanelShown && (
        <AppearancePanel showTitle={false} className={styles.appearancePanel} />
      )}
      <EmailOriginalOrTextViewButton docId={doc.id} isOriginalView={isOriginalView} />
    </>
  );
};

interface DefaultLeftHeaderProps {
  isAppearancePanelShown: boolean;
}

const DefaultLeftHeader = ({ isAppearancePanelShown }: DefaultLeftHeaderProps) => {
  return (
    <>
      <AppearanceButton />
      {isAppearancePanelShown && (
        <AppearancePanel showTitle={false} className={styles.appearancePanel} />
      )}
    </>
  );
};

interface DefaultRightHeaderProps {
  doc: FirstClassDocument;
  afterAction?: (...args: unknown[]) => void;
  parentPath: string;
  publicLinkButton: JSX.Element | null;
  isVideo: boolean;
}

const DefaultRightHeader = ({
  doc,
  afterAction,
  parentPath,
  publicLinkButton,
  isVideo,
}: DefaultRightHeaderProps) => {
  return (
    <div className={styles.right}>
      {publicLinkButton}

      <DocumentActionButtons
        docId={doc.id}
        docDocumentLocation={doc.triage_status}
        buttonClassName={styles.actionButton}
        afterAction={afterAction}
      />

      <DropdownDocHeader
        triggerClassName={styles.dropdownTriggerClassName}
        docId={doc.id}
        parentPath={parentPath}
      />

      {isVideo && (
        <>
          <div className={styles.videoHeaderDividerWrapper}>
            <div className={styles.videoHeaderDivider} />
          </div>

          <Tooltip content="Hide header">
            <Button className={styles.toggleVideHeaderOpenButton} onClick={toggleVideoHeaderOpen}>
              <ChevronUpIcon />
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
};

function NotebookRightHeader({
  parentDocument,
}: {
  parentDocument: FirstClassDocument;
}) {
  return (
    <DropdownNotebookHeader
      triggerClassName={styles.dropdownTriggerClassName}
      parentDocId={parentDocument.id}
    />
  );
}
